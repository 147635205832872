.title {
    text-align: center;
    font-size: 3rem;
}

.container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 500px));
    gap: 1rem;
    justify-content: center;
    align-items: flex-end;
    margin: auto;
}

.container h3 {
    margin-bottom: .5rem;
}

.error {
    grid-row: 2;
    background-color: #eb4d4b;
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
}

button.copy {
    padding: .75rem 1.5rem;
    cursor: pointer;
}

.result {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    align-items: flex-end;
}

.result > .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.result > .header h3 {
    margin-bottom: 0;
}

.result pre {
    background-color: #dfe6e9;
    height: 500px;
    margin: 0;
    width: 100%;
    border: 1px solid black;
    padding: 1rem;
    box-sizing: border-box;
    overflow-y: auto;
}

@media screen and (max-width: 900px) {
    .container {
        grid-template-columns: 1fr;
    }
}
